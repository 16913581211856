import * as moment from 'moment'

export const validDigits = (n) => {
  return n.replace(/[^0-9.]+/g, '')
}

export const validInteger = (n) => {
  return n.replace(/[^0-9]+/g, '')
}

export const CDN_PREFIX = "https://cdn.helius-rpc.com/cdn-cgi/image//"
export const calculateTimeLeft = endDate => {
  
  let difference = getTimeDifferenceWithAuction(endDate)
  let timeLeft = {}

  if (difference > 0) {
    const seconds = Math.floor((difference / 1000) % 60)
    const minutes = Math.floor((difference / 1000 / 60) % 60)
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    timeLeft['seconds'] = (seconds < 10 && '0') + seconds + ''
    timeLeft['minutes'] = (minutes < 10 && '0') + minutes + ''
    timeLeft['hours'] = (hours < 10 && '0') + hours + ''
    timeLeft['days'] = (days < 10 && '0') + days + ''
  } else {
    timeLeft = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    }
  }

  return timeLeft
}

export const calculateAuctionTimeLeft = endDate => {
  let difference = getTimeDifference(endDate)
  let timeLeft = {}

  if (difference > 0) {
    const seconds = Math.floor((difference / 1000) % 60)
    const minutes = Math.floor((difference / 1000 / 60) % 60)
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    timeLeft['seconds'] = (seconds < 10 && '0') + seconds + ''
    timeLeft['minutes'] = (minutes < 10 && '0') + minutes + ''
    timeLeft['hours'] = (hours < 10 && '0') + hours + ''
    timeLeft['days'] = (days < 10 && '0') + days + ''
  } else {
    timeLeft = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    }
  }

  return timeLeft
}

export const getTimeDifference = endTime => {
  let endListingTime = new Date(endTime)
  let offset = endListingTime.getTimezoneOffset() * 60 * 1000
  let endLocalTime = endListingTime.getTime() - offset
  let nowDate = new Date().getTime()
  let difference = endLocalTime - nowDate
  return difference
}

export const getTimeDifferenceWithAuction = endTime => {
  let endListingTime = new Date(endTime)
  let endLocalTime = endListingTime.getTime()
  let nowDate = new Date().getTime()
  let difference = endLocalTime - nowDate
  return difference
}

export const isExpired = endTime => {
  let endDate = new Date(endTime)
  let offsetEnd = endDate.getTimezoneOffset() * 60 * 1000
  let proposalEndTime = endDate.getTime() - offsetEnd

  let end_difference = proposalEndTime - new Date().getTime()
  if (end_difference < 0) return true
  return false
}

export const isEndDateExpired = endTime => {
  let endDate = new Date(endTime)
  let offsetEnd = endDate.getTimezoneOffset() * 60 * 1000
  let proposalEndTime = endDate.getTime() - offsetEnd

  let end_difference = proposalEndTime - new Date().getTime()
  if (end_difference < 0) return true
  return false
}

export const isExpiredDate = (start, duration) => {
  // for testing
  let proposalTime = new Date(start)
  let offset = proposalTime.getTimezoneOffset() * 60 * 1000
  let proposalLocalTime = proposalTime.getTime() - offset
  let nowDate = new Date().getTime()
  let difference = nowDate - proposalLocalTime

  if (difference < duration * 3600 * 1000) return false
  else return true
}

export const isAvailableDate = (start, end) => {
  // for testing
  let startTime = new Date(start)
  let offset = startTime.getTimezoneOffset() * 60 * 1000
  let proposalStartTime = startTime.getTime() - offset

  let endTime = new Date(end)
  let offsetEnd = startTime.getTimezoneOffset() * 60 * 1000
  let proposalEndTime = endTime.getTime() - offsetEnd

  let nowDate = new Date().getTime()
  let diffStart = nowDate - proposalStartTime
  let diffEnd = proposalEndTime - nowDate
  if (diffStart > 0 && diffEnd > 0) return true
  else return false
}

export const containsSpecialChars = (text) => {
  return /[\\\/:*?<>#]/.test(text);
}

export const containsEmoji = (text) => {
  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}\u{2B55}\u{2934}\u{2935}\u{2B05}-\u{2B07}\u{2B1B}\u{2B1C}\u{25AA}\u{25AB}\u{25FE}\u{25FD}\u{25FB}\u{25FC}\u{25AA}\u{25AB}\u{25FE}\u{25FD}\u{3030}\u{303D}\u{3297}\u{3299}\u{FE0F}\u{200D}\u{20E3}]+/u;
  return emojiRegex.test(text);
}

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const getDateLabel = date => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let month = months[date.getMonth()]
  let day = date.getDate()
  let year = date.getFullYear()
  return month + ' ' + day + ', ' + year
}

export const getDateTimeLabel = date => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let month = months[date.getMonth()]
  let day = date.getDate()
  let year = date.getFullYear()
  let hours = date.getHours()
  let mins = date.getMinutes()
  return month + ' ' + day + ', ' + year + ' ' + hours + ':' + mins
}

export const getYMDString = date => {
  let month = date.getMonth() + 1
  return month + '/' + date.getDate() + '/' + date.getFullYear()
}

export const getTimeLabel = date => {
  var localDate = new Date(date)
  let hours = localDate.getHours()
  let mins = localDate.getMinutes()

  return hours + ':' + mins
}

export const convertMerchDescription = desc => {
  if (desc === 'Unisex Premium Hoodie') return 'Premium Hoodie'
  else if (desc === 'Unisex Organic Cotton T-Shirt') return 'Premium Tee'
  else if (desc === 'Unisex Long Sleeve Tee') return 'Premium Long Sleeve'
  else if (desc === 'Unisex Eco Sweatshirt') return 'Premium Crewneck'
  else if (desc === "Women's Fitted Eco Tee") return 'Women’s Tee'
}

export const convertMerchType = type => {
    if (type === 'Direct-to-Garment') return 'DTG'
    return type
  }

export const findElement = (arr, propName, propValue) => {
  for (var i = 0; i < arr.length; i++) if (arr[i][propName] == propValue) return arr[i]
}

export const calculateTimeLeftAll = endDate => {
  let difference = +new Date(endDate) - +new Date()

  let timeLeft = {}

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    }
    const seconds = Math.floor((difference / 1000) % 60)
    const minutes = Math.floor((difference / 1000 / 60) % 60)
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))

    timeLeft = (seconds < 10 && '0') + seconds + 's'
    if (minutes > 0) {
      timeLeft = (minutes < 10 && '0') + minutes + 'm' + ' ' + timeLeft
    } else {
      if (hours > 0 || days > 0) timeLeft = '00m ' + timeLeft
    }
    if (hours > 0) {
      timeLeft = (hours < 10 && '0') + hours + 'h' + ' ' + timeLeft
    } else {
      if (days > 0) timeLeft = '00h ' + timeLeft
    }
    if (days > 0) {
      timeLeft = (days < 10 && '0') + days + 'd' + ' ' + timeLeft
    }
  } else {
    timeLeft = '00h 00m 00s'
  }

  return timeLeft
}

export const countDecimals = value => {
  if (Math.floor(value) === value) return 0
  return value.toString().split('.')[1].length || 0
}

export const isString = obj => {
  return obj !== undefined && obj !== null && obj.constructor == String
}

export const formattedAmount = value => {
  const floatValue = parseFloat(value)
  if (Math.floor(floatValue) === floatValue) return value
  let length = value.toString().split('.')[0].length
  if (length === 0) {
    value = '0' + value
  }
  length = value.toString().split('.')[1]?.length
  if (length > 1) return parseFloat(value).toFixed(2)
  else return value
}

export const isLoggedIn = () => {
  if (typeof window === 'undefined') return false
  const jwt_token = localStorage.getItem('token') || ''
  return jwt_token.length > 0
}

export const validUrl = url => {
  if (!url.includes('http')) {
    return 'https://' + url
  }
  if (!url.includes('https')) {
    return url.replace(/^http:\/\//i, 'https://')
  }
  return url
}

export const isValidUrl = (urlString, socialType) => {
  var inputElement = document.createElement('input')
  inputElement.type = 'url'
  inputElement.value = urlString

  if (!inputElement.checkValidity() || urlString.includes(socialType) <= 0) {
    return false
  } else {
    return true
  }
}

export const copyToClipboard = value => {
  navigator.clipboard.writeText(value.replaceAll(' ', '%20'))
}

export const copyClipboardAlert = identifier => {
  document.getElementById(identifier).style.display = 'flex'
  setTimeout(function () {
    document.getElementById(identifier).style.display = 'none'
  }, 1000)
}

export const copyLongClipboardAlert = identifier => {
  document.getElementById(identifier).style.display = 'block'
  setTimeout(function () {
    document.getElementById(identifier).style.display = 'none'
  }, 3000)
}

export const getOwnerName = item => {
  if (item.owner_username !== null && item.owner_username !== '') return item.owner_username

  if (item.owner_address !== null) return item.owner_address

  return 'N/A'
}

export const getBuyerName = item => {
  if (item.reference_username !== null && item.reference_username !== '') return item.reference_username

  if (item.reference_address !== null)
    return item.reference_address

  return 'N/A'
}

export const getSliceAddress = address => {
  return address ? address.slice(0, 5) + '***' + address.slice(-3) : ''
}

export const getLocalDate = dateString => {
  var utcDate = moment.utc(dateString)
  var fmt = 'MM/DD/YYYY hh:mm:ss'
  const localDate = utcDate.local().format(fmt)
  return localDate
}

export const getLocalDateWithoutTime = dateString => {
  var utcDate = moment.utc(dateString)
  var fmt = 'MM/DD/YYYY'
  const localDate = utcDate.local().format(fmt)
  return localDate
}

export const getFirstUpperString = word => {
  if (word === null || word?.length === 0) return ''
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const compareDate = (a, b) => {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
}

export const optimizeCdnUrl = (urlString: string, options?: Record<string, string | number>): string => {
  let url = new URL(urlString, 'https://fostermarketplace.com')
  try {
    if (url.hostname == 'cdn.helius-rpc.com' && url.pathname.startsWith('/cdn-cgi/image//')) {
      let assetUrl = url.pathname.substring('/cdn-cgi/image//'.length)
      let parameters = Object.entries(options ?? {})
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([k, v]) => k + '=' + v.toString())
        .join(',')
      if (['arweave.net', 'gateway.irys.xyz'].includes(new URL(assetUrl).hostname))
        url.pathname = `/cdn-cgi/image/${parameters}/${assetUrl}`
    }
  } catch (e: any) {
    console.error(`could not optimize cdn url: ${urlString}: ${e?.message}`)
  }
  return url.toString()
}

export const optimizeCdnUrlOgImage = (urlString: string): string =>
  optimizeCdnUrl(urlString, {
    quality: 100,
    width: 1200,
  })

export const setColorTheme = lightMode => {
  const doc = document.documentElement
  if (lightMode) {
    // Light Mode
    doc.style.setProperty('--main-bg-color', '#202427')
    doc.style.setProperty('--main-bg-color-rgb', '32, 36, 39')
    doc.style.setProperty('--main-icon-color', '#202427')
    doc.style.setProperty('--main-icon-color-rgb', '32, 36, 39')
    doc.style.setProperty('--g-left-container-bg', '#FFF')
    doc.style.setProperty('--bg-new-color', '#EEEDEB')
    doc.style.setProperty('--background-main-color', '#F3F2F0')
    doc.style.setProperty('--background-main-color-rgb', '244, 243, 240')
    doc.style.setProperty('--background-black-color', '#202427')
    doc.style.setProperty('--background-black-color-rgb', '239, 240, 247')
    doc.style.setProperty('--preload-back-color', 'rgba(32, 36, 39, 0.7)')
    doc.style.setProperty('--active-btn-border-color', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--solar-icon-color', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--background-light-color', '#202427')
    doc.style.setProperty('--background-light-color-rgb', '32, 36, 39')
    doc.style.setProperty('--text-gray-200-color', '#202427')
    doc.style.setProperty('--text-gray-200-color-rgb', '32, 36, 39')
    doc.style.setProperty('--sort-item-color', 'rgba(32, 36, 39, 0.75)')
    doc.style.setProperty('--header-bg-color', 'rgba(244, 243, 240, 0.8)')
    doc.style.setProperty('--nav-bg-color', 'rgba(238, 237, 235, 0.75)')
    doc.style.setProperty('--nav-bg-shadow', 'rgba(32, 36, 39, 0.4)')
    doc.style.setProperty('--second-home-bg', '#FFF')
    doc.style.setProperty('--header-bottom-color', '#CCCDD4')
    doc.style.setProperty('--header-mobile-bg-color', 'rgba(244, 243, 240, 0.75)')
    doc.style.setProperty('--outline-button-border', '#202427')
    doc.style.setProperty('--outline-button-color', '#202427')
    doc.style.setProperty('--secondary-button-border', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--avatar-border-color1', '#202427')
    doc.style.setProperty('--avatar-border-color2', '#6a6c6e')
    doc.style.setProperty('--text-trending-card-color', 'rgba(149, 219, 213, 1)')
    doc.style.setProperty('--dashboard-tab-color', 'rgba(32, 36, 39, 0.6)')
    doc.style.setProperty('--activity-bg-color', '#FFF')
    doc.style.setProperty('--activity-td-color', 'rgba(32, 36, 39, 1)')
    doc.style.setProperty('--activity-border-color', '#B9BBC1')
    doc.style.setProperty('--activity-box-color', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--accordion-first-row-bg', '#F3F2F0')
    doc.style.setProperty('--modal-bg-color', '#F3F2F0')
    doc.style.setProperty('--accordion-second-row-bg', 'rgba(32, 36, 39, 0.05)')
    doc.style.setProperty('--artwork-bg-color', '#F3F2F0')
    doc.style.setProperty('--artwork-bg-mobile-color', '#DDDCDC')
    doc.style.setProperty('--clipboard-btn-color', '#C8C9CF')
    doc.style.setProperty('--step-circle-mobile-color', '#202427')
    doc.style.setProperty('--dimension-bg-color', '239, 240, 247')
    doc.style.setProperty('--radio-check-color', '#202427')
    doc.style.setProperty('--brand-02-color-rgb', '55, 130, 135')
    doc.style.setProperty('--switch-01-color', '149, 219, 213')
    doc.style.setProperty('--switch-02-color', '43,43,43')
    doc.style.setProperty('--menu-bg-color', '#F3F2F0')
    doc.style.setProperty('--category-bg-color', '#F3F2F0')
    doc.style.setProperty('--time-slog-bg', '#FFF')
    doc.style.setProperty('--primary-btn-hover', '#202427')
    doc.style.setProperty('--primary-btn-color', '#eff0f6')
    doc.style.setProperty('--twitter-text-color', '#eff0f6')
    doc.style.setProperty('--twitter-btn-color1', '#F3F2F0')
    doc.style.setProperty('--twitter-btn-color2', '#F3F2F0')
    doc.style.setProperty('--notification-card-bg', 'rgba(32, 36, 39, 0.5)')
    doc.style.setProperty('--background-dark-color-rgb', '135, 137, 141')
    doc.style.setProperty('--dao-title-color', '#202427')
    doc.style.setProperty('--nft-detail-artist', '#202427')
    doc.style.setProperty('--dropdown-bg-color', 'rgba(205, 205, 205, 0.8)')
    doc.style.setProperty('--dropdown-border-color', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--dropdown-menu-bg', '#202427')
    doc.style.setProperty('--dropdown-menu-hover-color', '#95DBD5')
    doc.style.setProperty('--dropdown-menu-hover-border', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--wallet-green-text', '#202427')
    doc.style.setProperty('--wallet-error-text', '#202427')
    doc.style.setProperty('--wallet-active-text', '#202427')
    doc.style.setProperty('--follow-text-color', '#202427')
    doc.style.setProperty('--follow-bg-color', 'linear-gradient(to right, #4C706C, #95DBD5)')
    doc.style.setProperty(
      '--hero-bg-color',
      'linear-gradient(to bottom, rgba(243, 242, 240, 0), rgba(243, 242, 240, 1)'
    )
    doc.style.setProperty('--artist-switch-color', 'linear-gradient(to right, #eff0f6, #78787B)')
    doc.style.setProperty('--artist-switch-bg', '#CBCDD2')
    doc.style.setProperty('--address-btn-color', 'rgba(32, 36, 39, 0.75)')
    doc.style.setProperty('--mobile-sort-bg', '#F3F2F0')
    doc.style.setProperty('--search-bg-color', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--main-search-bg-color', '#F3F2F0')
    doc.style.setProperty('--search-category-color', '#202427')
    doc.style.setProperty('--menu-item-bg', 'linear-gradient(to bottom, #F3F2F0, #E8E8E8)')
    doc.style.setProperty('--menu-item-open-bg', '#FFF')
    doc.style.setProperty('--menu-item-open-color', 'rgba(32, 36, 39, 1)')
    doc.style.setProperty('--menu-item-border', 'linear-gradient(to bottom, transparent, transparent)')
    doc.style.setProperty('--menu-open-item-border', 'linear-gradient(to bottom, transparent, transparent)')
    doc.style.setProperty('--menu-item-right-icon', '#202427')
    doc.style.setProperty('--menu-item-right-open-icon', 'rgba(32, 36, 39, 1)')
    doc.style.setProperty('--pagination-down-icon', '#202427')
    doc.style.setProperty('--search-item-bg', '#202427')
    doc.style.setProperty('--search-item-color', '#95DBD5')
    doc.style.setProperty('--resource-card-title', '#eff0f6')
    doc.style.setProperty('--studio-title-bg', '#FFF')
    doc.style.setProperty('--switch-disabled-bg', '#CBCDD2')
    doc.style.setProperty('--nft-detail-section-border', '#eff0f6')
    doc.style.setProperty('--gradient-btn-color', '#FFF')
    doc.style.setProperty('--ellipse-btn-shadow', 'rgba(32, 36, 39, 0.3)')
    doc.style.setProperty('--nft-detail-bg', '#CBCDD2')
    doc.style.setProperty('--nft-detail-border', '#C5C7CC')
    doc.style.setProperty('--alpha-area-border', 'transparent')
    doc.style.setProperty('--alpha-area-bg', '#D8D8DE')
    doc.style.setProperty('--profile-menu-item-bg', 'rgba(32, 36, 39, 0.05)')
    doc.style.setProperty('--profile-menu-item-border', '')
    doc.style.setProperty('--profile-menu-item-color', '#95dbd5')
    doc.style.setProperty('--follow-box-shadow', 'rgba(32, 36, 39, 0.3)')
    doc.style.setProperty('--read-more-bg', 'rgba(180, 180, 180, 0.9)')
    doc.style.setProperty('--datepicker-bg-color', '#F3F2F0')
    doc.style.setProperty('--datepicker-button-bg', 'rgba(239, 240, 247, 1)')
    doc.style.setProperty('--datepicker-date-bg', 'rgba(255, 255, 255, 1)')
    doc.style.setProperty('--price-input-bg', '#DEDEE4')
    doc.style.setProperty('--modal-terms-service', '#202427')
    doc.style.setProperty('--step-text-color', '#E3E4EA')
    doc.style.setProperty('--select-collection-text', '#202427')
    doc.style.setProperty('--collection-mint-progress-bg', 'rgba(0, 0, 0, 0.6')
    doc.style.setProperty('--collection-mint-left-bg', '#CFD0D6')
    doc.style.setProperty('--connect-wallet-text', '#95dbd5')
    doc.style.setProperty('--connect-wallet-bg', '#202427')
    doc.style.setProperty('--mint-progress-bg', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--mint-progress-mobile-bg', 'rgba(12, 12, 12, 0.2)')
    doc.style.setProperty('--connected-wallet-bg', 'linear-gradient(to bottom, #E5E6EB, #C5C6CB)')
    doc.style.setProperty('--profile-border-color', '#ADAEB2')
    doc.style.setProperty('--profile-active-border-color', '#202427')
    doc.style.setProperty('--stats-accordion-bg', '#F3F2F0')
    doc.style.setProperty('--stats-accordion-border', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--stats-accordion-normal-border', 'transparent') //'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--resource-accordion-dropdown-bg', 'rgba(32, 36, 39, 1)')
    doc.style.setProperty('--resource-accordion-dropdown-color', '#95dbd5')
    doc.style.setProperty('--resource-accordion-close-color', '#eff0f6')
    doc.style.setProperty('--chevron-up-icon-color', '#202427')
    doc.style.setProperty('--collection-filter-title', '#202427')
    doc.style.setProperty('--deposit-btn-bg-1', '#4B6E6B')
    doc.style.setProperty('--deposit-btn-bg-2', '#95DBD5')
    doc.style.setProperty('--mint-btn-bg-1', '#202427')
    doc.style.setProperty('--mint-btn-bg-2', '#202427')
    doc.style.setProperty('--mint-btn-color', '#95DBD5')
    doc.style.setProperty('--wallet-box-shadow', '0px 0px 0px transparent')
    doc.style.setProperty('--wallet-btn-bg', '#202427')
    doc.style.setProperty('--wallet-btn-hover', '#95DBD5')
    doc.style.setProperty('--create-nft-card-bg', '#FFFFFF')
    doc.style.setProperty('--collection-card-bg', '#FFFFFF')
    doc.style.setProperty('--collection-card-bg-rgb', '255, 255, 255')
    doc.style.setProperty('--collection-card-border', '0px')
    doc.style.setProperty('--profile-card-bg', '#F3F2F0')
    doc.style.setProperty('--mynft-outline-border', '#95DBD5')
    doc.style.setProperty('--mynft-outline-color', '#EFF0F6')
    doc.style.setProperty('--filter-close-color', '#202427')
    doc.style.setProperty('--negative-btn-border', '#121212')
    doc.style.setProperty('--checkbox-border', 'transparent')
    doc.style.setProperty('--checkbox-border-color', '#202427')
    doc.style.setProperty('--checkbox-bg-color', 'transparent')
    doc.style.setProperty('--ellipse-menu-bg', '#c1c2c7')
    doc.style.setProperty('--ellipse-report-bg', '#FFF')
    doc.style.setProperty('--mobile-serachbox-bg', '#eff0f6')
    doc.style.setProperty('--mobile-serach-area-bg', 'rgba(239, 240, 247, 0.8')
    doc.style.setProperty('--modal-close-btn-bg', 'rgba(241, 255, 255, 1)')
    doc.style.setProperty('--auction-input-bg', '#202427')
    doc.style.setProperty('--see-more-border', '#D7D8DE')
    doc.style.setProperty('--follow-btn-border', 'D7D8DE')
    doc.style.setProperty('--follow-btn-border-mobile', 'rgba(32, 36, 39, 0.25)')
    doc.style.setProperty('--artist-card-bg', 'linear-gradient(to bottom, rgba(32, 36, 39, 1), rgba(238, 239, 244, 1)')
    doc.style.setProperty('--nft-info-bg', '#202427')
    doc.style.setProperty('--place-bid-close-rect', 'rgba(32, 36, 39, 0.05')
    doc.style.setProperty('--place-bid-close-path', '#202427')
    doc.style.setProperty('--input-bg', '#E0E1E7')
    doc.style.setProperty('--browse-nft-blur-bg', 'rgba(32, 36, 39, 0.75)')
    doc.style.setProperty('--resource-card-bg', '#FFF')
    doc.style.setProperty('--resource-card-default-bg', '#F3F2F0')
    doc.style.setProperty('--home-swiper-bg', '#202427')
    doc.style.setProperty('--explore-btn-bg-1', '#95DBD5')
    doc.style.setProperty('--explore-btn-bg-2', '#4B6E6B')
    doc.style.setProperty('--home-hero-title', '#414144')
    doc.style.setProperty('--profile-user-color', '#202427')
    doc.style.setProperty('--single-nft-btn-bg-1', '#4D706D')
    doc.style.setProperty('--single-nft-btn-bg-2', '#95DBD5')
    doc.style.setProperty('--single-nft-btn-color', '#202427')
    doc.style.setProperty('--drop-zone-bg', '#202427')
    doc.style.setProperty('--filter-btn-bg', '#BBBCC1')
    doc.style.setProperty('--filter-btn-border', '#EFF0F6')
    doc.style.setProperty('--ellipse-svg-1', 'transparent')
    doc.style.setProperty('--ellipse-svg-2', 'transparent')
    doc.style.setProperty('--ellipse-stop-2', '#202427')
    doc.style.setProperty('--tooltip-bg', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--tooltip-border', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--back-btn-color', 'transparent')
    doc.style.setProperty('--back-btn-border', 'rgba(32, 36, 39, 0.5)')
    doc.style.setProperty('--back-btn-border-mobile', 'rgba(32, 36, 39, 0.25)')
    doc.style.setProperty('--back-btn-border-width', '1')
    doc.style.setProperty('--artwork-table-border', 'transparent')
    doc.style.setProperty('--artwork-table-tr', '#202427')
    doc.style.setProperty('--artwork-table-tr-hover-bg', '#CBCDD2')
    doc.style.setProperty('--artwork-table-tr-hover-color', '#95DBD5')
    doc.style.setProperty('--deposit-icon-bg', '#95dbd5')
    doc.style.setProperty('--wallet-icon-bg-1', '#95dbd5')
    doc.style.setProperty('--wallet-icon-bg-2', '#95dbd5')
    doc.style.setProperty('--wallet-icon-bg-3', '#95dbd5')
    doc.style.setProperty('--wallet-icon-bg-4', '#95dbd5')
    doc.style.setProperty('--wallet-icon-bg-5', '#95dbd5')
    doc.style.setProperty('--wallet-icon-bg-6', '#95dbd5')
    doc.style.setProperty('--wallet-icon-light-bg-1', '#eff0f6')
    doc.style.setProperty('--wallet-icon-light-bg-2', '#eff0f6')
    doc.style.setProperty('--wallet-icon-light-bg-3', '#eff0f6')
    doc.style.setProperty('--wallet-icon-light-bg-4', '#eff0f6')
    doc.style.setProperty('--wallet-icon-light-bg-5', '#eff0f6')
    doc.style.setProperty('--wallet-icon-light-bg-6', '#eff0f6')
    doc.style.setProperty('--logout-icon-color', '#202427')
    doc.style.setProperty('--trash-icon-color', '#A9ACB1')
    doc.style.setProperty('--day-btn-color', '#eff0f6')
    doc.style.setProperty('--day-btn-bg-1', '#202427')
    doc.style.setProperty('--day-btn-bg-2', '#202427')
    doc.style.setProperty('--search-icon-color', '#D1D2D8')
    doc.style.setProperty('--switch-disabled-color', 'rgba(239, 240, 247, 1)')
    doc.style.setProperty('--logo-text-color', '#202427')
    doc.style.setProperty('--logo-text-ballon', '#202427')
    doc.style.setProperty('--logo-text-bottom', '#202427')
    doc.style.setProperty('--activity-tr-bg', '#F3F2F0')
    doc.style.setProperty('--banner-image-bg', '#505457')
    doc.style.setProperty('--menu-item-activity-icon', '#202427')
    doc.style.setProperty('--card-border-color', '#C1C2C9')
    doc.style.setProperty('--search-menu-icon-color', '#202427')
    doc.style.setProperty('--social-bg-color', 'rgba(32, 36, 39, 0.05)')
    doc.style.setProperty('--wallet-detail-bg', '#FFFFFF')
    doc.style.setProperty('--shipping-board-bg', 'rgba(32, 36, 39, 0.05)')
    doc.style.setProperty('--nft-border-color', '#D7D8DE')
    doc.style.setProperty('--address-btn-bg-color', 'rgba(32, 36, 39, 0.05)')
    doc.style.setProperty('--stats-bg-color', '#F3F2F0')
    doc.style.setProperty('--tab-bottom-color', '#202427')
    doc.style.setProperty('--artist-card-bg', 'linear-gradient(to bottom, rgba(32, 36, 39, 1), rgba(238, 239, 244, 1)')
    doc.style.setProperty('--top-artist-bg', '#202427')
    doc.style.setProperty('--sell-nft-border', 'transparent')
    doc.style.setProperty('--sell-nft-bg', '#E0E1E7')
    doc.style.setProperty('--setting-input-bg', '#CCCDD4')
    doc.style.setProperty('--search-icon-stop-1', '#95dbd5')
    doc.style.setProperty('--search-icon-stop-2', '#4B6E6B')
    doc.style.setProperty('--search-icon-bg', 'rgba(239, 240, 247, 0.6)')
    doc.style.setProperty('--search-gray-icon', 'rgba(32, 36, 39, 0.6)')
    doc.style.setProperty('--search-gray-icon-disable', 'rgba(32,36,39,0.2)')
    doc.style.setProperty('--profile-banner-overlay', '239, 240, 247')
    doc.style.setProperty('--follow-btn-bg', '#121212')
    doc.style.setProperty('--follow-btn-color', '#E0E1E7')
    doc.style.setProperty('--hero-overlay-color', 'rgba(243, 242, 240, 0.85)')
    doc.style.setProperty('--hide-nft-border-color', '#CBCDD2')
    doc.style.setProperty('--category-chip-bg', '#C4C5CA')
    doc.style.setProperty('--live-auction-card', '#FFF')
    doc.style.setProperty('--accordian-header-bg', '#FFF')
    doc.style.setProperty('--accordian-area-bg', '#FFF')
    doc.style.setProperty('--social-share-bg', '#ffffff')
    doc.style.setProperty('--social-share-bg-rgb', '255, 255, 255')
    doc.style.setProperty('--social-share-hover', 'rgba(214, 216, 221, 1)')
    doc.style.setProperty('--share-icon-bg', '#D6D8DD')
    doc.style.setProperty('--dao-setup-left', 'linear-gradient(to bottom, #eff0f6, #78787B)')
    doc.style.setProperty('--outline-btn-border', 'rgba(32, 36, 39, 0.25)')
    doc.style.setProperty('--report-menu-bg', 'rgba(255, 255, 255, 0.5)')
    doc.style.setProperty('--stats-label-bg', '#E0E1E7')
    doc.style.setProperty(
      '--category-bottom-mask',
      'linear-gradient(to bottom, rgba(243, 242, 240, 0), rgba(243, 242, 240, 1))'
    )
    doc.style.setProperty('--stats-search-bg', '#FFF')
    doc.style.setProperty('--artwork-tbl-header-bg', 'linear-gradient(to bottom, #FFF, #FFF)')
    doc.style.setProperty('--primary-btn-bg', '#2E3235')
    doc.style.setProperty('--gradient-btn-hover-bg', '#202427')
    doc.style.setProperty('--gradient-btn-hover-color', '#FFF')
    doc.style.setProperty('--primary-btn-border', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--auction-card-label', '#65686C')
    doc.style.setProperty('--auction-card-bg', 'linear-gradient(to right, #C8C8CD, #939396)')
    doc.style.setProperty('--auction-card-color', '#eff0f6')
    doc.style.setProperty('--dao-connect-border-width', '0px')
    doc.style.setProperty('--dao-connect-bg', 'linear-gradient(to bottom, #eff0f6, #78787B)')
    doc.style.setProperty('--dao-treasury-bg', '#FFF')
    doc.style.setProperty('--dropdown-filter-bg', 'transparent')
    doc.style.setProperty('--g-right-container-bg', '#F3F2F0')
    doc.style.setProperty('--deposit-disabled-color', '#5F6165')
    doc.style.setProperty('--nav-divider-color', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--hero-bg-color', 'linear-gradient(to bottom, rgba(243, 242, 240, 0), rgba(243, 242, 240, 1)')
    doc.style.setProperty('--profile-setup-bg', '#1A1A1A')
    doc.style.setProperty('--profile-setup-input-bg', 'rgba(239, 240, 247, 0.15)')
    doc.style.setProperty('--profile-setup-avatar-bg', '#202427')
    doc.style.setProperty('--profile-setup-left-bg', '#242424')
    doc.style.setProperty('--quick-buy-icon-color', '#eff0f6')
    doc.style.setProperty('--alert-close-bg-color', '#8F9093')
    doc.style.setProperty('--fade-loader-color', '#202427')
    doc.style.setProperty('--time-slot-bg', 'rgba(32, 36, 39, 0.05)')
    doc.style.setProperty('--time-slot-deselect-color', 'rgba(32, 36, 39, 0.7)')
    doc.style.setProperty('--back-btn-bg-color', '255, 255, 255')
    doc.style.setProperty('--merch-mobile-squre-bg', '#202427')
    doc.style.setProperty('--scroll-bar-thumb', 'rgba(32, 36, 39, 0.25)')
    doc.style.setProperty('--scroll-bar-bg', '#D9D8D7')
    doc.style.setProperty('--merch-disabled-icon-bg', '#FFF')
    doc.style.setProperty('--merch-disabled-icon-color', '#202427')
    doc.style.setProperty('--stats-btn-icon-color', 'rgba(32, 36, 39, 1)')
    doc.style.setProperty('--stats-btn-border-color', 'rgba(32, 36, 39, 0.25)')
    doc.style.setProperty('--scroll-arrow-bg-color', '#F3F2F0')
    doc.style.setProperty('--scroll-arrow-color', '#707070')
    doc.style.setProperty('--scroll-arrow-drop-shadow', 'rgba(0, 0, 0, 0.1)')
    doc.style.setProperty('--nft-generative-color', '#FFFFFF')
    doc.style.setProperty('--nft-type-box-hover', 'rgba(32, 36, 39, 0.25)')
    doc.style.setProperty('--size-chart-modal-title', '#F3F2F0')
    doc.style.setProperty('--table-header-color', '#573A01')
    doc.style.setProperty('--order-table-color', 'rgba(32, 36, 39, 0.02)')
    doc.style.setProperty('--toggle-bg-color', '#FFFFFF')
    doc.style.setProperty('--dashboard-toggle-color', '#FFFFFF')
    doc.style.setProperty('--selected-tab-color', 'rgba(32, 36, 39, 0.05)')
    doc.style.setProperty('--place-bid-color', '#EBEAE8')
    doc.style.setProperty('--place-bid-shadow-color-rgb', '32, 36, 39')
    doc.style.setProperty('--selected-tab-border-color', '#DDDCDC')
    doc.style.setProperty('--place-bid-minimum-color', '#242424')
    doc.style.setProperty('--place-bid-minvalue-color', '#242424')
    doc.style.setProperty('--view-item-btn-color', '#202427')
    doc.style.setProperty('--ellipse-blue', '#202427')
    doc.style.setProperty('--ellipse-green', '#202427')
    doc.style.setProperty('--notification-shadow-color', 'rgba(32, 36, 39, 0.15)')
    doc.style.setProperty('--liveauction-bg-color', 'rgba(32, 36, 39, 0.03)')
    doc.style.setProperty('--single-title-color', '#FFFFFF')
    doc.style.setProperty('--connect-wallet-color', 'linear-gradient(to right, #C1C1C1, #DADADA)')
    doc.style.setProperty('--solrplex-icon-color', 'rgba(32, 36, 39, 0.05)')
    doc.style.setProperty('--collection-type-card-color', '#202427')
    doc.style.setProperty('--connection-type-bg', '#FFFFFF')
    doc.style.setProperty('--create-collection-color', 'rgba(255, 255, 255, 0.6)')
    doc.style.setProperty('--create-collection-btn', 'rgba(32, 36, 39, 0.8)')
    doc.style.setProperty('--import-ea-border-color', '255, 255, 255')
    doc.style.setProperty('--collection-desc-bg-color', '#F3F2F0')
    doc.style.setProperty('--hero-card-bg', '#ECEBEA')
    doc.style.setProperty('--hero-avatar-bg-rgb', 'rgba(243, 242, 240, 0)')
    doc.style.setProperty('--explore-tab-border', '#202427')
    doc.style.setProperty('--chip-border-color', 'rgba(32, 26, 39, 0.1)')
    doc.style.setProperty('--audio-nft-bgr-rgb', '245, 244, 242')
    doc.style.setProperty('--bg-303030-FFF', '#FFF')
    doc.style.setProperty('--bg-303030-D9D8D7', '#D9D8D7')
    doc.style.setProperty('--bg-95dbd5-707070', '#707070')
    doc.style.setProperty('--bg-95dbd5-FFF', '#FFF')
    doc.style.setProperty('--bg-eff0f6-707070', '#707070')
    doc.style.setProperty('--bg-F5F5F3-1E1E1E', '#F5F3F2')
    doc.style.setProperty('--bg-B8B9BD-707070', '#707070')
    doc.style.setProperty('--bg-535355-DDDEDE', '#DDDEDE')
    doc.style.setProperty('--bg-95DBD5-DDDEDE', '#DDDEDE')
    doc.style.setProperty('--bg-1C1C1C-F3F2F0', '#F3F2F0')
    doc.style.setProperty('--bg-303030-202427', 'rgba(32, 36, 39, 0.1)')
    doc.style.setProperty('--bg-303030-D3D3D2', '#D3D3D2')
    doc.style.setProperty('--bg-303030-DDDCDC', '#DDDCDC')
    doc.style.setProperty('--bg-303030-D1D1CF', '#D1D1CF')
    doc.style.setProperty('--bg-timer-card', 'rgba(244, 243, 240, 0.8)') 
    doc.style.setProperty('--bg-141313-E8E7E5', '#E8E7E5')
    doc.style.setProperty('--bg-151515-E8E7E5', '#E8E7E5')
    doc.style.setProperty('--bg-1A1A1A-E8E7E5', '#E8E7E5')
    doc.style.setProperty('--bg-141313-F3F2F0', '#F3F2F0')
    doc.style.setProperty('--bg-1A1A1A-202427', '#202427')
    doc.style.setProperty('--bg-1A1A1A-E5E1DD', '#E5E1DD')
    doc.style.setProperty('--bg-151515-E5E1DD', '#E5E1DD')
    doc.style.setProperty('--bg-9bdbd5-20242725', 'rgba(32, 36, 39, 0.25)')
    doc.style.setProperty('--bg-1A1A1A-4A4D50', '#4A4D50')
    doc.style.setProperty('--bg-1A1A1A-F3F2F0', '#F3F2F0')
    doc.style.setProperty('--bg-1A1A1A-FFF', '#FFF')
    doc.style.setProperty('--bg-212121-F3F2F0', '#F3F2F0')
    doc.style.setProperty('--bg-212121-202427', '#202427')
    doc.style.setProperty('--bg-59595B-BEBFBD', '#BEBFBD')
    doc.style.setProperty('--bg-95dbd505-202427', '#202427')
    doc.style.setProperty('--bg-4D6A67-202427', '#202427')
    doc.style.setProperty('--bg-4D6A67-484B4E', '#484B4E')
    doc.style.setProperty('--bg-3A3A3A-202427', '#202427')
    doc.style.setProperty('--bg-FFF-202427', '#202427')
    doc.style.setProperty('--bg-1A1A1A-FFF-RGB', '255, 255, 255')
    doc.style.setProperty('--bg-242424-EFF0F605', 'rgba(239, 240, 247, 0.05)')
    doc.style.setProperty('--bg-242424-E8E7E6', '#E8E7E6')
    doc.style.setProperty('--bg-242424-FFF', '#FFF')
    doc.style.setProperty('--bg-242424-EEEDEB', '#EEEDEB')
    doc.style.setProperty('--bg-242424-E5E1DD', '#E5E1DD')
    doc.style.setProperty('--bg-0A0A0A-E5E1DD', '#E5E1DD')
    doc.style.setProperty('--bg-1a1a1a-EEEDEB', '238, 237, 235')
    doc.style.setProperty('--bg-1E1E1E-EEEDEB', '#EEEDEB')
    doc.style.setProperty('--bg-151515-1A1A1A', '#1A1A1A')
    doc.style.setProperty('--bg-151515-EEEDEB', '#EEEDEB')
    doc.style.setProperty('--bg-151515-EEEDEB-Gradient', 'linear-gradient(to bottom, #EEEDEB, #F3F2F0)')
    doc.style.setProperty('--bg-2B2B2B-DDDCDC', '#DDDCDC')
    doc.style.setProperty('--bg-TRANSPARENT-FFF', '#FFF')
    doc.style.setProperty('--bg-1E1E1E-F3F2F0', '#F3F2F0')
    doc.style.setProperty('--bg-1E1E1E-F7F6F4', '#F7F6F4')
    doc.style.setProperty('--bg-171717-FFF', '#FFF')
    doc.style.setProperty('--bg-171717-F3F2F0', '#F3F2F0')
    doc.style.setProperty('--bg-EFF0F610-F3F2F0', '#F3F2F0')
    doc.style.setProperty('--bg-2C2C2C-D1D1CF', '#D1D1CF')
    doc.style.setProperty('--bg-0A0A0A-E8E7E5', '#E8E7E5')
    doc.style.setProperty('--bg-242424-F3F2F0', '#F3F2F0')
    doc.style.setProperty('--bg-F3F2F0-202427', '32, 36, 39')
    doc.style.setProperty('--bg-2B2F32-FFF', '#FFF')
    // =====================================================
    // =====================================================
    // =====================================================
  } else {
    // Dark Mode
    doc.style.setProperty('--main-bg-color', '#eff0f6')
    doc.style.setProperty('--main-bg-color-rgb', '239, 240, 247')
    doc.style.setProperty('--main-icon-color', '#95dbd5')
    doc.style.setProperty('--main-icon-color-rgb', '149, 219, 213')
    doc.style.setProperty('--g-left-container-bg', '#1B1B1B')
    doc.style.setProperty('--g-right-container-bg', '#121212')
    doc.style.setProperty('--bg-new-color', '#151515')
    doc.style.setProperty('--background-main-color', '#1a1a1a')
    doc.style.setProperty('--background-main-color-rgb', '26, 26, 26')
    doc.style.setProperty('--background-black-color', '#121212')
    doc.style.setProperty('--background-black-color-rgb', '18, 18, 18')
    doc.style.setProperty('--preload-back-color', 'rgba(18, 18, 18, 0.3)')
    doc.style.setProperty('--active-btn-border-color', '#95dbd5')
    doc.style.setProperty('--solar-icon-color', '#eff0f6')
    doc.style.setProperty('--background-light-color', '#eff0f6')
    doc.style.setProperty('--background-light-color-rgb', '239, 240, 247')
    doc.style.setProperty('--text-gray-200-color', '#eff0f6')
    doc.style.setProperty('--text-gray-200-color-rgb', '239, 240, 247')
    doc.style.setProperty('--sort-item-color', '#eff0f6')
    doc.style.setProperty('--header-bg-color', 'rgba(18, 18, 18, 0.7)')
    doc.style.setProperty('--nav-bg-color', 'rgba(42, 42, 42, 0.75)')
    doc.style.setProperty('--nav-bg-shadow', 'rgba(0, 0, 0, 0.4)')
    doc.style.setProperty('--second-home-bg', '#242424')
    doc.style.setProperty('--header-bottom-color', 'rgba(239, 240, 247, 0.15)')
    doc.style.setProperty('--header-mobile-bg-color', 'rgba(18, 18, 18, 0.6)')
    doc.style.setProperty('--search-menu-icon-color', '#eff0f6')
    doc.style.setProperty('--outline-button-border', '#5ac3ba')
    doc.style.setProperty('--outline-button-color', '#eff0f6')
    doc.style.setProperty('--secondary-button-border', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--avatar-border-color1', '#4ea19b')
    doc.style.setProperty('--avatar-border-color2', '#304e4e')
    doc.style.setProperty('--text-trending-card-color', 'rgba(239, 240, 247, 0.75)')
    doc.style.setProperty('--dashboard-tab-color', 'rgba(239, 240, 247, 0.6)')
    doc.style.setProperty('--activity-bg-color', '#242424')
    doc.style.setProperty('--activity-td-color', 'rgba(239, 240, 247, 0.8)')
    doc.style.setProperty('--activity-border-color', '#95dbd5')
    doc.style.setProperty('--activity-default-border-color', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--activity-box-color', 'transparent')
    doc.style.setProperty('--accordion-first-row-bg', '#1a1a1a')
    doc.style.setProperty('--modal-bg-color', '#1b1b1b')
    doc.style.setProperty('--accordion-second-row-bg', 'rgba(239, 240, 247, 0.05)')
    doc.style.setProperty('--artwork-bg-color', '#2B2B2B')
    doc.style.setProperty('--artwork-bg-mobile-color', '#242424')
    doc.style.setProperty('--clipboard-btn-color', '#202427')
    doc.style.setProperty('--step-circle-mobile-color', '#95dbd5')
    doc.style.setProperty('--dimension-bg-color', '12, 12, 12')
    doc.style.setProperty('--radio-check-color', '#95dbd5')
    doc.style.setProperty('--brand-02-color-rgb', '55, 130, 135')
    doc.style.setProperty('--switch-01-color', '55, 130, 135')
    doc.style.setProperty('--switch-02-color', '12, 65, 68')
    doc.style.setProperty('--menu-bg-color', '#1a1a1a')
    doc.style.setProperty('--category-bg-color', '#242424')
    doc.style.setProperty('--time-slog-bg', '#1a1a1a')
    doc.style.setProperty('--primary-btn-hover', '#95dbd5')
    doc.style.setProperty('--primary-btn-color', '#1a1a1a')
    doc.style.setProperty('--hero-artist-color', '#95dbd5')
    doc.style.setProperty('--twitter-text-color', '#202427')
    doc.style.setProperty('--twitter-btn-color1', 'rgba(149, 219, 213, 0.05)')
    doc.style.setProperty('--twitter-btn-color2', 'rgba(149, 219, 213, 0.05)')
    doc.style.setProperty('--notification-card-bg', 'rgba(239, 240, 247, 0.15)')
    doc.style.setProperty('--background-dark-color-rgb', '32, 36, 39')
    doc.style.setProperty('--back-btn-bg-color', '18, 18, 18')
    doc.style.setProperty('--dao-title-color', '#95dbd5')
    doc.style.setProperty('--nft-detail-artist', '#95dbd5')
    doc.style.setProperty('--dropdown-bg-color', 'rgba(14, 14, 14, 0.8)')
    doc.style.setProperty('--dropdown-border-color', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--dropdown-menu-bg', '#2B2B2B')
    doc.style.setProperty('--dropdown-menu-hover-color', '')
    doc.style.setProperty('--dropdown-menu-hover-border', '')
    doc.style.setProperty('--wallet-green-text', '#95dbd5')
    doc.style.setProperty('--wallet-error-text', '#deefd0')
    doc.style.setProperty('--wallet-active-text', '#95dbd5')
    doc.style.setProperty('--follow-text-color', '#95dbd5')
    doc.style.setProperty('--follow-bg-color', 'linear-gradient(to bottom, #121212, #2d625d)')
    doc.style.setProperty('--hero-bg-color', 'linear-gradient(to bottom, rgba(26, 26, 26, 0), rgba(26, 26, 26, 1)')
    doc.style.setProperty('--artist-switch-color', 'linear-gradient(to right, #202427, #173437)')
    doc.style.setProperty('--artist-switch-bg', '#202427')
    doc.style.setProperty('--address-btn-color', 'rgba(239, 240, 247, 0.75)')
    doc.style.setProperty('--mobile-sort-bg', '#121212')
    doc.style.setProperty('--search-bg-color', 'rgba(239, 240, 247, 0.05)')
    doc.style.setProperty('--main-search-bg-color', '#1a1a1a')
    doc.style.setProperty('--menu-item-bg', 'linear-gradient(to bottom, #1B1B1B, #121212')
    doc.style.setProperty('--menu-item-open-bg', '#242424')
    doc.style.setProperty('--search-category-color', 'rgba(239, 240, 247, 0.6)')
    doc.style.setProperty('--menu-item-open-color', 'rgba(239, 240, 247, 1)')
    doc.style.setProperty('--menu-item-border', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--menu-open-item-border', '#95dbd5')
    doc.style.setProperty('--menu-item-right-icon', '#95dbd5')
    doc.style.setProperty('--menu-item-right-open-icon', '#ffffff')
    doc.style.setProperty('--pagination-down-icon', '#ffffff')
    doc.style.setProperty('--search-item-bg', '#2B2B2B')
    doc.style.setProperty('--search-item-color', '#eff0f6')
    doc.style.setProperty('--resource-card-title', '#95dbd5')
    doc.style.setProperty('--studio-title-bg', '#2B2B2B')
    doc.style.setProperty('--switch-disabled-bg', '#2B2B2B')
    doc.style.setProperty('--nft-detail-section-border', '#202427')
    doc.style.setProperty('--gradient-btn-color', '#eff0f6')
    doc.style.setProperty('--ellipse-btn-shadow', '#95dbd5')
    doc.style.setProperty('--nft-detail-bg', '#121212')
    doc.style.setProperty('--nft-detail-border', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--alpha-area-border', '#95dbd5')
    doc.style.setProperty('--alpha-area-bg', 'transparent')
    doc.style.setProperty('--profile-menu-item-bg', 'rgba(239, 240, 247, 0.05)')
    doc.style.setProperty('--profile-menu-item-border', '#5ac3ba')
    doc.style.setProperty('--profile-menu-item-color', '#eff0f6')
    doc.style.setProperty('--follow-box-shadow', '#95dbd5')
    doc.style.setProperty('--read-more-bg', 'rgba(18, 18, 18, 0.8)')
    doc.style.setProperty('--datepicker-bg-color', '#1A1A1A')
    doc.style.setProperty('--datepicker-button-bg', 'rgba(32, 36, 39, 1)')
    doc.style.setProperty('--datepicker-date-bg', '#242424')
    doc.style.setProperty('--price-input-bg', '#121212')
    doc.style.setProperty('--modal-terms-service', '#9db2b4')
    doc.style.setProperty('--step-text-color', '#202427')
    doc.style.setProperty('--select-collection-text', '#eff0f6')
    doc.style.setProperty(
      '--collection-mint-progress-bg',
      'linear-gradient( 90deg, rgba(48, 88, 86, 0.5) 0%, rgba(20, 32, 33, 0.5) 50%, rgba(32, 36, 39, 0.5) 100%)'
    )
    doc.style.setProperty('--collection-mint-left-bg', '#121212')
    doc.style.setProperty('--connect-wallet-text', '#eff0f6')
    doc.style.setProperty(
      '--connect-wallet-bg',
      'linear-gradient(to bottom, rgba(55, 130, 135, 1), rgba(36, 66, 70, 1))'
    )
    doc.style.setProperty('--mint-progress-bg', 'rgba(12, 12, 12, 0.5)')
    doc.style.setProperty('--mint-progress-mobile-bg', 'rgba(12, 12, 12, 0.5)')
    doc.style.setProperty('--connected-wallet-bg', 'linear-gradient(to bottom, #1B1B1B, #2B2B2B)')
    doc.style.setProperty('--profile-border-color', '#95dbd5')
    doc.style.setProperty('--profile-active-border-color', '#95dbd5')
    doc.style.setProperty('--stats-accordion-bg', '#1A1A1A')
    doc.style.setProperty('--stats-accordion-border', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--stats-accordion-normal-border', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--resource-accordion-dropdown-bg', 'rgba(255, 255, 255, 0.2)')
    doc.style.setProperty('--resource-accordion-dropdown-color', '#eff0f6')
    doc.style.setProperty('--resource-accordion-close-color', '#95dbd5')
    doc.style.setProperty('--chevron-up-icon-color', '#95DBD5')
    doc.style.setProperty('--collection-filter-title', '#95DBD5')
    doc.style.setProperty('--deposit-btn-bg-1', '#2B4142')
    doc.style.setProperty('--deposit-btn-bg-2', '#59BFB7')
    doc.style.setProperty('--mint-btn-bg-1', '#95DBD5')
    doc.style.setProperty('--mint-btn-bg-2', '#4B6E6B')
    doc.style.setProperty('--mint-btn-color', '#202427')
    doc.style.setProperty('--wallet-box-shadow', '0px 3px 6px rgba(18, 18, 18, 0.4)')
    doc.style.setProperty('--wallet-btn-bg', 'linear-gradient(110deg, #121212, #202427)')
    doc.style.setProperty('--wallet-btn-hover', '#95dbd5')
    doc.style.setProperty('--create-nft-card-bg', '#242424')
    doc.style.setProperty('--collection-card-bg', '#1a1a1a')
    doc.style.setProperty('--collection-card-bg-rgb', '26, 26, 26')
    doc.style.setProperty('--collection-card-border', '1px')
    doc.style.setProperty('--profile-card-bg', '#1a1a1a')
    doc.style.setProperty('--mynft-outline-border', '#5ac3ba')
    doc.style.setProperty('--mynft-outline-color', '')
    doc.style.setProperty('--filter-close-color', '#eff0f6')
    doc.style.setProperty('--negative-btn-border', '#95DBD5')
    doc.style.setProperty('--checkbox-border-color', '#95DBD5')
    doc.style.setProperty('--checkbox-bg-color', '#95DBD5')
    doc.style.setProperty('--ellipse-menu-bg', '#1b1b1b')
    doc.style.setProperty('--ellipse-report-bg', '#1b1b1b')
    doc.style.setProperty('--mobile-serachbox-bg', '#1b1b1b')
    doc.style.setProperty('--mobile-serach-area-bg', '#313233')
    doc.style.setProperty('--modal-close-btn-bg', 'rgba(239, 240, 246, 0.2)')
    doc.style.setProperty('--auction-input-bg', '#202427')
    doc.style.setProperty('--see-more-border', 'rgba(239, 240, 247, 0.25)')
    doc.style.setProperty('--follow-btn-border', '#95dbd5')
    doc.style.setProperty('--follow-btn-border-mobile', 'rgba(239, 240, 247, 0.25)')
    doc.style.setProperty('--artist-card-bg', 'rgba(32, 36, 39, 0.7)')
    doc.style.setProperty('--nft-info-bg', 'rgba(32, 36, 39, 0.5)')
    doc.style.setProperty('--place-bid-close-rect', 'rgba(239, 240, 247, 0.05)')
    doc.style.setProperty('--place-bid-close-path', '#eff0f6')
    doc.style.setProperty('--input-bg', 'rgba(255, 255, 255, 0.25)')
    doc.style.setProperty('--browse-nft-blur-bg', 'rgba(32, 36, 39, 0.75)')
    doc.style.setProperty('--resource-card-bg', '#2B2B2B')
    doc.style.setProperty('--resource-card-default-bg', '#121212')
    doc.style.setProperty('--home-swiper-bg', '#9E9DA0')
    doc.style.setProperty('--explore-btn-bg-1', '#5ac3ba')
    doc.style.setProperty('--explore-btn-bg-2', '#378287')
    doc.style.setProperty('--home-hero-title', '#eff0f6')
    doc.style.setProperty('--profile-user-color', '#95dbd5')
    doc.style.setProperty('--single-nft-btn-bg-1', '#121212')
    doc.style.setProperty('--single-nft-btn-bg-2', '#2D625D')
    doc.style.setProperty('--single-nft-btn-color', '#95DBD5')
    doc.style.setProperty('--drop-zone-bg', 'rgba(255, 255, 255, 0.05)')
    doc.style.setProperty('--filter-btn-bg', '#272727')
    doc.style.setProperty('--filter-btn-border', 'rgba(239,240,247, 0.1)')
    doc.style.setProperty('--ellipse-svg-1', '#63d6cd')
    doc.style.setProperty('--ellipse-svg-2', '#63d6cd')
    doc.style.setProperty('--tooltip-bg', 'rgba(239, 240, 247, 0.2)')
    doc.style.setProperty('--tooltip-border', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--back-btn-color', '#378287')
    doc.style.setProperty('--back-btn-border', '#95dbd5')
    doc.style.setProperty('--back-btn-border-mobile', 'rgba(239, 240, 247, 0.1')
    doc.style.setProperty('--back-btn-border-width', '1')
    doc.style.setProperty('--artwork-table-border', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--artwork-table-tr', 'rgba(239, 240, 247, 0.8)')
    doc.style.setProperty('--artwork-table-tr-hover-bg', '#202427')
    doc.style.setProperty('--artwork-table-tr-hover-color', '#eff0f6')
    doc.style.setProperty('--deposit-icon-bg', '#95dbd5')
    doc.style.setProperty('--wallet-icon-bg-1', '#9945ff')
    doc.style.setProperty('--wallet-icon-bg-2', '#8752f3')
    doc.style.setProperty('--wallet-icon-bg-3', '#5497d5')
    doc.style.setProperty('--wallet-icon-bg-4', '#43b4ca')
    doc.style.setProperty('--wallet-icon-bg-5', '#28e0b9')
    doc.style.setProperty('--wallet-icon-bg-6', '#19fb9b')
    doc.style.setProperty('--wallet-icon-light-bg-1', '#9945ff')
    doc.style.setProperty('--wallet-icon-light-bg-2', '#8752f3')
    doc.style.setProperty('--wallet-icon-light-bg-3', '#5497d5')
    doc.style.setProperty('--wallet-icon-light-bg-4', '#43b4ca')
    doc.style.setProperty('--wallet-icon-light-bg-5', '#28e0b9')
    doc.style.setProperty('--wallet-icon-light-bg-6', '#19fb9b')
    doc.style.setProperty('--logout-icon-color', '#95dbd5')
    doc.style.setProperty('--trash-icon-color', '#eff0f6')
    doc.style.setProperty('--day-btn-color', '#95dbd5')
    doc.style.setProperty('--day-btn-bg-1', '#262627')
    doc.style.setProperty('--day-btn-bg-2', '#2b2b2b')
    doc.style.setProperty('--search-icon-color', '#121212')
    doc.style.setProperty('--switch-disabled-color', 'rgba(239, 240, 247, 0.75)')
    doc.style.setProperty('--logo-text-color', '#fff')
    doc.style.setProperty('--logo-text-ballon', '#95dbd5')
    doc.style.setProperty('--logo-text-bottom', '#ADAEB2')
    doc.style.setProperty('--activity-tr-bg', '#2B2B2B')
    doc.style.setProperty('--banner-image-bg', '#ffffff')
    doc.style.setProperty('--menu-item-activity-icon', '#95dbd5')
    doc.style.setProperty('--card-border-color', '#333333')
    doc.style.setProperty('--social-bg-color', 'rgba(239, 240, 247, 0.05)')
    doc.style.setProperty('--wallet-detail-bg', '#242424')
    doc.style.setProperty('--shipping-board-bg', 'rgba(239, 240, 247, 0.05)')
    doc.style.setProperty('--nft-border-color', '#292929')
    doc.style.setProperty('--address-btn-bg-color', 'rgba(239, 240, 247, 0.05)')
    doc.style.setProperty('--stats-bg-color', '#1a1a1a')
    doc.style.setProperty('--tab-bottom-color', '#95dbd5')
    doc.style.setProperty('--top-artist-bg', 'linear-gradient(to bottom, rgba(149, 219, 213, 1), rgba(75, 110, 107, 1)')
    doc.style.setProperty('--sell-nft-border', 'rgba(255, 255, 255, 0.1)')
    doc.style.setProperty('--sell-nft-bg', '#121212')
    doc.style.setProperty('--setting-input-bg', '#202427')
    doc.style.setProperty('--search-gray-icon', 'rgba(239, 240, 247, 0.6)')
    doc.style.setProperty('--search-icon-stop-1', '#202427')
    doc.style.setProperty('--search-icon-stop-2', '#2D625D')
    doc.style.setProperty('--search-icon-bg', 'rgba(32, 36, 39, 0.6)')
    doc.style.setProperty('--search-gray-icon-disable', 'rgba(255, 255, 255, 0.2)')
    doc.style.setProperty('--profile-banner-overlay', '18, 18, 18')
    doc.style.setProperty('--follow-btn-bg', '#1b1b1b')
    doc.style.setProperty('--follow-btn-color', '#eff0f6')
    doc.style.setProperty('--hero-overlay-color', 'rgba(26, 26, 26, 0.85)')
    doc.style.setProperty('--hide-nft-border-color', '#95dbd5')
    doc.style.setProperty('--category-chip-bg', 'rgba(239, 240, 247, 0.15)')
    doc.style.setProperty('--live-auction-card', '#1b1b1b')
    doc.style.setProperty('--accordian-header-bg', '#1b1b1b')
    doc.style.setProperty('--accordian-area-bg', '#1b1b1b')
    doc.style.setProperty('--social-share-bg', '#121212')
    doc.style.setProperty('--social-share-bg-rgb', '18, 18, 18')
    doc.style.setProperty(
      '--social-share-hover',
      'linear-gradient(to bottom, rgba(90, 195, 186, 0.3) 0%, rgba(45, 98, 93, 0.3) 100%)'
    )
    doc.style.setProperty('--share-icon-bg', '#2B2B2B')
    doc.style.setProperty('--dao-setup-left', 'linear-gradient(to bottom, #121212, #173639)')
    doc.style.setProperty('--outline-btn-border', 'rgba(239, 240, 246, 0.25)')
    doc.style.setProperty('--report-menu-bg', 'rgba(18, 18, 18, 0.5)')
    doc.style.setProperty('--stats-label-bg', '#1B1B1B')
    doc.style.setProperty(
      '--category-bottom-mask',
      'linear-gradient(to bottom, rgba(18, 18, 18, 0), rgba(18, 18, 18, 1))'
    )
    doc.style.setProperty('--stats-search-bg', '#2b2b2b')
    doc.style.setProperty('--artwork-tbl-header-bg', '#242424')
    doc.style.setProperty('--primary-btn-bg', '#232626')
    doc.style.setProperty('--gradient-btn-hover-bg', '#252A29')
    doc.style.setProperty('--gradient-btn-hover-color', '#95DBD5')
    doc.style.setProperty('--primary-btn-border', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--auction-card-label', '#95dbd5')
    doc.style.setProperty('--auction-card-bg', 'linear-gradient(to right, #4b6e6b, #95dbd5)')
    doc.style.setProperty('--auction-card-color', '#202427')
    doc.style.setProperty('--dao-connect-border-width', '1px')
    doc.style.setProperty('--dao-connect-bg', 'transparent')
    doc.style.setProperty('--dao-treasury-bg', '#242424')
    doc.style.setProperty('--dropdown-filter-bg', '#242424')
    doc.style.setProperty('--deposit-disabled-color', '#E2E6EA')
    doc.style.setProperty('--nav-divider-color', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--profile-setup-bg', '#1A1A1A')
    doc.style.setProperty('--profile-setup-input-bg', 'rgba(239, 240, 247, 0.15)')
    doc.style.setProperty('--profile-setup-avatar-bg', 'linear-gradient(300deg, #4a9791 55%, #202427 100%)')
    doc.style.setProperty('--profile-setup-left-bg', '#242424')
    doc.style.setProperty('--quick-buy-icon-color', '#202427')
    doc.style.setProperty('--alert-close-bg-color', '#434343')
    doc.style.setProperty('--fade-loader-color', '#63D6CD')
    doc.style.setProperty('--time-slot-bg', 'rgba(97, 97, 97, 0.05)')
    doc.style.setProperty('--time-slot-deselect-color', 'rgba(239, 240, 247, 1)')
    doc.style.setProperty('--merch-mobile-squre-bg', '#1b1b1b')
    doc.style.setProperty('--scroll-bar-thumb', 'rgba(239, 240, 247, 0.15)')
    doc.style.setProperty('--scroll-bar-bg', '#242424')
    doc.style.setProperty('--merch-disabled-icon-bg', '#2B2B2B')
    doc.style.setProperty('--merch-disabled-icon-color', '#eff0f6')
    doc.style.setProperty('--stats-btn-icon-color', 'rgba(239, 240, 247, 0.75)')
    doc.style.setProperty('--stats-btn-border-color', 'rgba(255, 255, 255, 0.25)')
    doc.style.setProperty('--scroll-arrow-bg-color', '#2B2B2B')
    doc.style.setProperty('--scroll-arrow-color', '#eff0f6')
    doc.style.setProperty('--scroll-arrow-drop-shadow', 'rgba(32, 36, 37, 0.1)')
    doc.style.setProperty('--nft-generative-color', '#2F2F2F')
    doc.style.setProperty('--nft-type-box-hover', 'rgba(149, 219, 213, 0.25)')
    doc.style.setProperty('--size-chart-modal-title', '#242424')
    doc.style.setProperty('--table-header-color', '#242424')
    doc.style.setProperty('--order-table-color', 'rgba(239, 240, 246, 0.02)')
    doc.style.setProperty('--toggle-bg-color', '#95DBD5')
    doc.style.setProperty('--dashboard-toggle-color', '#242424')
    doc.style.setProperty('--selected-tab-color', 'rgba(150, 163, 236, 0.05)')
    doc.style.setProperty('--selected-tab-border-color', '#303030')
    doc.style.setProperty('--place-bid-color', '#232323')
    doc.style.setProperty('--place-bid-shadow-color-rgb', '32, 36, 39')
    doc.style.setProperty('--place-bid-minimum-color', '#95DBD5')
    doc.style.setProperty('--place-bid-minvalue-color', '#eff0f6')
    doc.style.setProperty('--view-item-btn-color', '#242424')
    doc.style.setProperty('--ellipse-blue', '#5C8CCE')
    doc.style.setProperty('--ellipse-green', '#95dbd5')
    doc.style.setProperty('--notification-shadow-color', 'rgba(0, 0, 0, 0.16)')
    doc.style.setProperty('--liveauction-bg-color', 'rgba(239, 240, 246, 0.03)')
    doc.style.setProperty('--single-title-color', '#202427')
    doc.style.setProperty('--connect-wallet-color', 'linear-gradient(to right, #242424, #1F1F1F)')
    doc.style.setProperty('--solrplex-icon-color', 'rgba(149, 219, 213, 0.05)')
    doc.style.setProperty('--collection-type-card-color', '#1a1a1a')
    doc.style.setProperty('--connection-type-bg', '#95dbd5')
    doc.style.setProperty('--create-collection-color', 'rgba(239, 240, 246, 0.6)')
    doc.style.setProperty('--create-collection-btn', 'rgba(36, 36, 36, 0.8)')
    doc.style.setProperty('--import-ea-border-color', '239, 240, 246')
    doc.style.setProperty('--collection-desc-bg-color', '#2F2F2F')
    doc.style.setProperty('--hero-card-bg', '#212121')
    doc.style.setProperty('--hero-avatar-bg-rgb', 'rgba(27, 27, 27, 0)')
    doc.style.setProperty('--explore-tab-border', 'rgba(239, 240, 246, 0.5)')
    doc.style.setProperty('--chip-border-color', 'rgba(236, 238, 244, 0.6)')
    doc.style.setProperty('--audio-nft-bgr-rgb', '51, 51, 51')
    doc.style.setProperty('--bg-303030-FFF', '#303030')
    doc.style.setProperty('--bg-303030-D9D8D7', '#303030')
    doc.style.setProperty('--bg-95dbd5-707070', '#95dbd5')
    doc.style.setProperty('--bg-95dbd5-FFF', '#95dbd5')
    doc.style.setProperty('--bg-F5F5F3-1E1E1E', '#1E1E1E')
    doc.style.setProperty('--bg-151515-E8E7E5', '#151515')
    doc.style.setProperty('--bg-1A1A1A-E8E7E5', '#1A1A1A')
    doc.style.setProperty('--bg-eff0f6-707070', '#eff0f6')
    doc.style.setProperty('--bg-B8B9BD-707070', '#B8B9BD')
    doc.style.setProperty('--bg-535355-DDDEDE', '#535355')
    doc.style.setProperty('--bg-95DBD5-DDDEDE', '#95DBD5')
    doc.style.setProperty('--bg-1C1C1C-F3F2F0', '#1C1C1C')
    doc.style.setProperty('--bg-303030-202427', '#303030')
    doc.style.setProperty('--bg-FFF-202427', '#FFF')
    doc.style.setProperty('--bg-303030-D3D3D2', '#303030')
    doc.style.setProperty('--bg-303030-DDDCDC', '#303030')
    doc.style.setProperty('--bg-303030-D1D1CF', '#303030')
    doc.style.setProperty('--bg-timer-card', 'rgba(26, 26, 26, 0.5)')
    doc.style.setProperty('--bg-141313-E8E7E5', '#141313')
    doc.style.setProperty('--bg-141313-F3F2F0', '#141313')
    doc.style.setProperty('--bg-1A1A1A-202427', '#1A1A1A')
    doc.style.setProperty('--bg-1A1A1A-E5E1DD', '#1A1A1A')
    doc.style.setProperty('--bg-151515-E5E1DD', '#151515')
    doc.style.setProperty('--bg-242424-EEEDEB', '#242424')
    doc.style.setProperty('--bg-9bdbd5-20242725', '#95dbd5')
    doc.style.setProperty('--bg-1A1A1A-4A4D50', '#1A1A1A')
    doc.style.setProperty('--bg-1A1A1A-F3F2F0', '#1A1A1A')
    doc.style.setProperty('--bg-1A1A1A-FFF', '#1A1A1A')
    doc.style.setProperty('--bg-212121-F3F2F0', '#212121')
    doc.style.setProperty('--bg-EFF0F610-F3F2F0', 'rgba(239, 240, 247, 0.1)')
    doc.style.setProperty('--bg-212121-202427', '#212121')
    doc.style.setProperty('--bg-59595B-BEBFBD', '#59595B')
    doc.style.setProperty('--bg-95dbd505-202427', 'rgba(149, 219, 213, 0.05)')
    doc.style.setProperty('--bg-4D6A67-202427', '#4D6A67')
    doc.style.setProperty('--bg-4D6A67-484B4E', '#4D6A67')
    doc.style.setProperty('--bg-3A3A3A-202427', '#3A3A3A')
    doc.style.setProperty('--bg-1A1A1A-FFF-RGB', '26, 26, 26')
    doc.style.setProperty('--bg-242424-EFF0F605', '#242424')
    doc.style.setProperty('--bg-242424-FFF', '#242424')
    doc.style.setProperty('--bg-242424-E8E7E6', '#242424')
    doc.style.setProperty('--bg-242424-E5E1DD', '#242424')
    doc.style.setProperty('--bg-0A0A0A-E5E1DD', '#0A0A0A')
    doc.style.setProperty('--bg-1a1a1a-EEEDEB', '26, 26, 26')
    doc.style.setProperty('--bg-1E1E1E-EEEDEB', '#1E1E1E')
    doc.style.setProperty('--bg-151515-1A1A1A', '#151515')
    doc.style.setProperty('--bg-151515-EEEDEB', '#151515')
    doc.style.setProperty('--bg-151515-EEEDEB-Gradient', 'linear-gradient(to bottom, #151515, #1A1A1A)')
    doc.style.setProperty('--bg-2B2B2B-DDDCDC', '#2B2B2B')
    doc.style.setProperty('--bg-TRANSPARENT-FFF', 'transparent')
    doc.style.setProperty('--bg-1E1E1E-F3F2F0', '#1E1E1E')
    doc.style.setProperty('--bg-1E1E1E-F7F6F4', '#1E1E1E')
    doc.style.setProperty('--bg-171717-FFF', '#171717')
    doc.style.setProperty('--bg-171717-F3F2F0', '#171717')
    doc.style.setProperty('--bg-2C2C2C-D1D1CF', '#2C2C2C')
    doc.style.setProperty('--bg-0A0A0A-E8E7E5', '#0A0A0A')
    doc.style.setProperty('--bg-242424-F3F2F0', '#242424')
    doc.style.setProperty('--bg-2B2F32-FFF', '#2B2F32')
    doc.style.setProperty('--bg-F3F2F0-202427', '243, 242, 240')
  }
}
